import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';

import { OrderService } from '../order.service';
import { UserService } from 'src/app/user.service';
import { DataService } from 'src/app/shared/data.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Order } from '../order';
import { Router } from '@angular/router';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  pageTitleObj = {
    title: 'Bestellung abschließen'
  };

  currentOrder;

  paymentMethods = [];
  orderTypes = [];

  paymentMethodFormGroup: FormGroup;

  chosenMethod;
  chosenOrderType;

  totalOrderPrice = 0;

  orderId;

  totalErrorMsg = '';

  constructor(private orderService: OrderService, private userService: UserService, private dataService: DataService,
              private formBuilder: FormBuilder, private router: Router) { }

  ngOnInit() {

    const idSuffix = Math.floor(Math.random() * ( 999 - 100 + 1 ) + 100);
    this.orderId = (moment().unix() + idSuffix).toString();

    // something is riiiiiili wrong
    if (typeof this.orderId === 'undefined' || this.orderId.toString().includes('nan-undefinedundefined')) {
      this.totalErrorMsg = 'Fehler aufgetreten. Bitte kontaktieren Sie den Administrator';
      return;
    }

    this.setCurrentOrder();
    this.userService.currentDaySelected$.subscribe((day) => {
      this.setCurrentOrder();
    });

    this.dataService.getAll('paymentMethod').subscribe((paymentMethods) => {
      this.paymentMethods = paymentMethods;
    });

    this.dataService.getAll('orderType').subscribe((orderTypes) => {
      this.orderTypes = orderTypes;
    });

    this.paymentMethodFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }

  setCurrentOrder() {
    this.currentOrder = this.orderService.getCurrentDayOrder();

    let totalOrderPrice = 0;
    this.currentOrder.items.forEach(element => {
      totalOrderPrice += element.price * element.qty;
    });

    this.totalOrderPrice = +totalOrderPrice.toFixed(2);
  }

  deleteOrderItem(item) {
    this.orderService.deleteOrderItem(item);
  }

  submitOrder() {

    const orderObj = new Order({
      food: this.currentOrder.items,
      orderTime: new Date().toString(),
      orderDay: this.orderService.currentDay.day,
      orderDate: this.orderService.currentDay.date,
      orderTypeId: this.chosenOrderType && this.chosenOrderType.id || this.orderTypes[0].id,
      paymentMethodId: this.chosenMethod && this.chosenMethod.id || this.paymentMethods[0].id,
      paymentStatus: 'pending', // TODO: ovo ce se morati napraviti nekako na serveru da se mijenja,
                                // ne smije klijent moci direktno mijenjati ovu vrijednost
      userId: this.userService.user.local.id,
      companyId: this.userService.user.local.companyId,
      orderMonthYear: moment(this.orderService.currentDay.date, 'LL').format('MM/YYYY')
    });

    delete orderObj.orderPrice;

    this.orderService.saveOrder(this.orderId, orderObj).then((data) => {
      this.router.navigate(['/orders/' + this.currentOrder.day]);
      this.orderService.clearCurrentDayOrder$.next();
    });

  }

}
