import { Component, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';

import { environment } from 'src/environments/environment';
import { PaymentService } from '../payment.service';
import { HttpClient } from '@angular/common/http';

declare var Stripe: any;

@Component({
  selector: 'app-stripe-form',
  templateUrl: './stripe-form.component.html',
  styleUrls: ['./stripe-form.component.scss']
})
export class StripeFormComponent implements AfterViewInit {

  @Input() price: number;
  @Input() orderId: string;
  @Output() submitPayment = new EventEmitter();

  isLoading = false;

  constructor(private paymentService: PaymentService, private http: HttpClient) { }

  ngAfterViewInit() {
    // Your Stripe public key
    const stripe = Stripe(environment.stripeKey);

    setTimeout(() => {

      // Create `card` element that will watch for updates
      // and display error messages
      const elements = stripe.elements({
        locale: 'de'
      });
      const card = elements.create('card', {
        hidePostalCode: true,
      });
      card.mount('#card-element');
      card.addEventListener('change', event => {
        const displayError = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });

      const paymentForm = document.getElementById('payment-form');
      paymentForm.addEventListener('submit', event => {
        event.preventDefault();

        this.isLoading = true;

        const url = 'https://us-central1-catering-37f8f.cloudfunctions.net/paymentIntent';
        const requestBody = {
          orderId: this.orderId,
          amount:  +this.price * 100
        };

        this.http.post(url, requestBody).subscribe((data: any) => {
          this.payWithCard(stripe, card, data.clientSecret);
        });

      });
    });

  }

  payWithCard(stripe, card, clientSecret) {
    stripe
    .confirmCardPayment(clientSecret, {
      payment_method: {
        card
      }
    })
    .then((result) => {
      if (result.error) {
        // Show error to your customer
        this.showError(result.error.message);
      } else {

        const obj = {
          amount: +this.price * 100,
          token: result.paymentIntent
        };

        // send this data to transactions db
        this.paymentService.save(this.orderId, obj).then(() => {
          console.log('Order saved!');
        });

        // also save order
        this.submitPayment.emit();
      }
    });
  }

  // Show the customer the error from Stripe if their card fails to charge
  showError(errorMsgText) {
    this.isLoading = false;
    const errorMsg = document.querySelector('#card-errors');
    errorMsg.textContent = errorMsgText;
    setTimeout(() => {
      errorMsg.textContent = '';
    }, 5000);
  }

}
