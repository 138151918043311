import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/user.service';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-cart-icon-menu',
  templateUrl: './cart-icon-menu.component.html',
  styleUrls: ['./cart-icon-menu.component.scss']
})
export class CartIconMenuComponent implements OnInit {

  selectedDay;
  currentOrder;

  constructor(private userService: UserService, private orderService: OrderService) { }

  ngOnInit() {
    this.getCurrentOrder();

    this.orderService.clearCurrentDayOrder$.subscribe(() => {
      this.clear();
    });

    this.userService.currentDaySelected$.subscribe((day) => {
      this.selectedDay = day;
      this.getCurrentOrder();
    });
  }

  getCurrentOrder() {

    if (typeof this.selectedDay === 'undefined') {
      return;
    }

    this.currentOrder = this.orderService.currentOrders[this.selectedDay.date];
  }

  deleteOrderItem(item) {
    this.orderService.deleteOrderItem(item);
  }

  clear() {
    this.currentOrder.items = [];
  }

}
