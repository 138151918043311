import { Component, OnInit, AfterViewInit, HostBinding, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { fromEvent } from 'rxjs';
import { throttleTime, map, pairwise, distinctUntilChanged, share, filter } from 'rxjs/operators';

// enum VisibilityState {
//   Visible = 'visible',
//   Hidden = 'hidden'
// }

// enum Direction {
//   Up = 'Up',
//   Down = 'Down'
// }

@Component({
  selector: 'app-page-header-img',
  templateUrl: './page-header-img.component.html',
  styleUrls: ['./page-header-img.component.scss']
})
export class PageHeaderImgComponent {

  // private isVisible = true;

  // @HostBinding('@toggle')
  // get toggle(): VisibilityState {
  //   return this.isVisible ? VisibilityState.Visible : VisibilityState.Hidden;

  // @Input() pageTitle: {title: string, description: string};
  // }

  constructor() { }

  // ngAfterViewInit() {
  //   const scroll$ = fromEvent(window, 'scroll').pipe(
  //     throttleTime(10),
  //     map(() => window.pageYOffset),
  //     pairwise(),
  //     map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
  //     distinctUntilChanged(),
  //     share()
  //   );

  //   const scrollUp$ = scroll$.pipe(
  //     filter(direction => direction === Direction.Up)
  //   );

  //   const scrollDown = scroll$.pipe(
  //     filter(direction => direction === Direction.Down)
  //   );

  //   scrollUp$.subscribe(() => (this.isVisible = true));
  //   scrollDown.subscribe(() => (this.isVisible = false));
  // }

}
