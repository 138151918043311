import { Component, OnInit } from '@angular/core';
import { Order } from './order';
import { OrderService } from './order.service';
import { trigger, transition, style, animate, query, stagger, animateChild } from '@angular/animations';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { MatBottomSheet } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  animations: [
    trigger('items', [
      transition(':enter', [
        style({ transform: 'scale(0.8)', opacity: 0 }),  // initial
        animate('0.3s cubic-bezier(.8, -0.6, 0.26, 1.6)',
          style({ transform: 'scale(1)', opacity: 1 }))  // final
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate('0.2s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({
            transform: 'scale(0.8)', opacity: 0,
            height: '0px', margin: '0px'
          }))
      ])
    ]),
    trigger('list', [
      transition(':enter', [
        query('@items', stagger(80, animateChild()), { optional: true })
      ]),
    ])
  ]
})
export class OrderComponent implements OnInit {

  pageTitleObj = {
    title: 'Bestellungen',
    description: 'Die Liste Ihrer vorherigen und aktiven Bestellungen'
  };

  allOrders: Array<Order> = [];
  displayedOrders: Array<Order> = [];

  orderDate;
  showOnlyActive = true;

  constructor(private orderService: OrderService, private bottomSheet: MatBottomSheet,
              private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    this.route.params.subscribe((params) => {
      this.orderDate = params.orderDate || 'all';

      this.orderService.getAllFood().then(() => {

        this.orderService.getUserOrders().pipe(map(orders => {
          orders.forEach((order) => {
            order.hasPaymentIssue = this.hasPaymentIssue(order);
            order.active = moment(order.orderDate, 'LL').isAfter(moment(new Date(), 'LL'));
          });

          return orders.sort(this.sortOrdersFn);
        })).subscribe((orders) => {

          this.allOrders = orders;

          if (this.showOnlyActive) {
            this.displayedOrders = orders.filter((o) => o.active);
          }

          if (typeof this.orderDate === 'undefined' || this.orderDate === 'all') {
            return;
          }

          const filteredOrders = this.allOrders.filter((o) => o.orderDate === this.orderDate);
          if (filteredOrders.length === 0) {
            return;
          }

          this.editOrder(filteredOrders[0]);
        });
      });
    });
  }

  sortOrdersFn(a, b) {

    if (moment(a.orderDate, 'LL').isBefore(moment(b.orderDate, 'LL'))) {
      return 1;
    }

    if (moment(a.orderDate, 'LL').isAfter(moment(b.orderDate, 'LL'))) {
      return -1;
    }

    return 0;
  }

  displayOrders() {
    this.showOnlyActive = !this.showOnlyActive;
    if (this.showOnlyActive) {
      this.displayedOrders = this.allOrders.filter((o) => o['active']);
      return;
    }

    this.displayedOrders = this.allOrders;
  }

  editOrder(order: Order) {
    this.bottomSheet.open(OrderDetailsComponent, {
      data: { order }
    });
  }

  hasPaymentIssue(order: Order) {
    const isCard = this.orderService.paymentMethodsHash[order.paymentMethodId].internalName === 'creditCard';
    return isCard && order.paymentStatus === 'pending';
  }

}
