export class CurrentOrder {
  items: Array<any>;
  day: string;

  constructor(order?) {

    if (typeof order === 'undefined') {
      this.items = [];
      return;
    }

    this.items = order.items || [];
    this.day = order.day;
  }
}
