import { Injectable } from '@angular/core';
import { DataService } from 'src/app/shared/data.service';
import { UserService } from 'src/app/user.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  context = 'payment';

  constructor(private dataService: DataService, private userService: UserService) { }

  save(orderId, obj) {
    return this.dataService.addPayment(this.userService.user.local.id, orderId, obj);
  }
}
