import { Component, OnInit } from '@angular/core';
import { Menu, MenuList } from './menu';
import { Food } from '../food/food';
import { DataService } from '../shared/data.service';
import { UserService } from '../user.service';
import { MatDialog, MatBottomSheet } from '@angular/material';
import { FoodModalComponent } from '../shared/food-modal/food-modal.component';
import { trigger, transition, style, animate, query, stagger, animateChild } from '@angular/animations';
import { OrderService } from '../order/order.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('items', [
      transition(':enter', [
        style({ transform: 'scale(0.6)', opacity: 0 }),  // initial
        animate('0.3s cubic-bezier(.8, -0.6, 0.26, 1.6)',
          style({ transform: 'scale(1)', opacity: 1 }))  // final
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate('0.2s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({
            transform: 'scale(0.6)', opacity: 0,
            height: '0px', margin: '0px'
          }))
      ])
    ]),
    trigger('list', [
      transition(':enter', [
        query('@items', stagger(80, animateChild()))
      ]),
    ]),
    trigger('menuTypes', [
      transition(':enter', [
        style({ transform: 'translate3d(0, -100%, 0)', visibility: 'visible' }),  // initial
        animate('0.3s ease',
          style({ transform: 'translate3d(0, 0, 0)', opacity: 1 }))  // final
      ]),
      transition(':leave', [
        style({ transform: 'translate3d(0, 0, 0)'}),
        animate('0.2s ease-out',
          style({
            transform: 'translate3d(0, -100%, 0)', opacity: 0 }))
      ])
    ])
  ]
})
export class MenuComponent implements OnInit {

  menus: Menu[] = [];

  food: Food[] = [];

  foodDetails: any = {};

  activeMenu;
  activeMenuObj;

  expanded: any = {};

  isMobile;

  currentCity;

  // pdfZoom;

  // corsAnywhere = 'https://cors-anywhere.herokuapp.com/';
  // corsAnywhere = '';
  // pdfSrc = this.corsAnywhere + 'https://firebasestorage.googleapis.com/v0/b/catering-37f8f.appspot.com/o/allergene.pdf?alt=media&token=01c4efd5-d367-49fd-a7da-aa2b52751651';

  // visiblePdf = false;

  chosenMenu = false;

  constructor(private dataService: DataService, private userService: UserService,
              public dialog: MatDialog, private bottomSheet: MatBottomSheet,
              private orderService: OrderService, private router: Router) { }

  ngOnInit() {

    this.isMobile = this.userService.isMobile;

    // this.pdfZoom = this.isMobile ? 2 : 1;

    this.currentCity = this.orderService.currentCitySelected;

    this.dataService.getAllOrderedBy('menu', 'position').subscribe((menus: Menu[]) => {

      console.log(this.userService.currentDaySelected, menus);

      const newMenu = [];

      menus.forEach(element => {

        // if (element.type === 'daily' && this.userService.currentDaySelected && element.day === this.userService.currentDaySelected.internalDay + this.userService.currentDaySelected.weekNumber) {
        //   // element.name = element.name.replace(this.userService.currentDaySelected.internalDay, '');
        //  newMenu.push(element);
        // } else if (element.type === 'single') {
         newMenu.push(element);
        // }
      });

      // this.activeMenu = newMenu[0].id;

      this.menus = newMenu;

      // this.setFoodList();

    });

    // this.orderService.togglePdfView$.subscribe((value) => {
    //   this.visiblePdf = value || false;
    // });

  }

  changeMenu(menu) {
    this.chosenMenu = true;
    this.activeMenu = menu.id;
    this.activeMenuObj = menu;
    this.setFoodList();
  }

  backToMenu() {
    this.chosenMenu = false;
    this.activeMenu = undefined;
    this.activeMenuObj = undefined;
    this.food = [];
  }

  backToChooseCity() {
    this.router.navigate(['/choose-city']);
  }

  setFoodList() {

    // const dailyMenu = this.menus.find((menu) => menu.type === 'daily');

    // console.log('daily and ative', dailyMenu, this.activeMenu)

    console.log('activeMenu', this.activeMenu);

    console.log('activeCity', this.orderService.currentCitySelected);

    this.dataService.queryByProperty('food', 'menuId', this.activeMenu).subscribe((food) => {

      // const priority = {
      //   'Soft Drinks': 1,
      //   Drinks: 2,
      //   Smoothies: 3,
      //   'Alkoholhaltige Getränke': 4,
      //   undefined: 5,
      //   '': 6
      // };

      // const extraFields = {price: 9.9} as any;

      // if (dailyMenu && this.activeMenu === dailyMenu.id) {
      //   extraFields.dailyMenuOptions = true;
      //   extraFields.price = 9.9;
      // }

      // console.log('dailyMenudailyMenudailyMenu', dailyMenu, dailyMenu.id, this.activeMenu === dailyMenu.id)

      food.forEach((el, key) => {
        console.log(el);
        if ((typeof el.image !== 'undefined' && el.image.length === 0) || typeof el.image === 'undefined') {
          el.image = 'https://images2.imgbox.com/d8/b5/dE456L4W_o.png';
          food[key].image = el.image;


          // food[key] = {...food[key], ...extraFields};
        }

        // if (dailyMenu && this.activeMenu === dailyMenu.id) {
        //   food[key].price = 8.9;
        //   food[key].dailyMenuOptions = true;
        // }

      });

      this.food = food;

      // if (this.activeMenu === '2' && dailyMenu) { // ID 2 je fastfood :mindblown:
      //   this.dataService.queryByProperty('food', 'menuId', dailyMenu.id).subscribe((food2) => {

      //     food2.forEach((el, key) => {
      //       if (typeof el.image !== 'undefined' && el.image.length === 0) {
      //         el.image = 'https://cdn.dribbble.com/users/1012566/screenshots/4187820/topic-2.jpg';
      //         food2[key].image = el.image;
      //       }

      //     });

      //     this.food = [...food2, ...this.food];
      //   });
      // }

      console.log(this.food);
      this.foodDetails = {};
    });
  }

  toggleFoodDetails(id) {
    const foodDetails = {};
    if (typeof this.foodDetails[id] === 'undefined' || !this.foodDetails[id]) {
      foodDetails[id] = true;
    }
    this.foodDetails = foodDetails;
  }

  expandCard(food) {
    this.expanded = {};
    this.expanded[food.id] = true;
    food.qty = food.qty || 1;

    const dialogRef = this.bottomSheet.open(FoodModalComponent, {
      data: { food }
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    //   // this.animal = result;
    // });
    // }
  }

  order(food) {

    if (this.userService.user.isAnonymous) {
      this.router.navigate(['/user/edit']);
      return;
    }

    if (!this.orderService.currentOrdersSetup) {
      const dateObj = new Date();
      const date = moment(dateObj).format('LL');
      const day = moment(dateObj).format('dddd');
      this.userService.currentDaySelected$.next({dateObj, date, day});
    }

    this.orderService.addFood(food);
  }

  // zoom(add) {
  //   if (add) {
  //     this.pdfZoom += 0.5;
  //     return;
  //   }

  //   if (this.pdfZoom - 0.5 < 1) {
  //     return;
  //   }

  //   this.pdfZoom -= 0.5;
  // }

}
