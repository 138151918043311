import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/user.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { trigger, transition, style, animate } from '@angular/animations';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
  animations: [
    trigger('formEl', [
      transition(':enter', [
        style({ transform: 'scale(0.8)', opacity: 0 }),  // initial
        animate('0.3s cubic-bezier(.8, -0.6, 0.26, 1.6)',
          style({ transform: 'scale(1)', opacity: 1 }))  // final
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate('0.2s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({
            transform: 'scale(0.8)', opacity: 0,
            height: '0px', margin: '0px'
          }))
      ])
    ])
  ]
})
export class EditUserComponent implements OnInit {

  @ViewChild('editUserForm', {static: false}) private editUserForm: NgForm;

  pageTitleObj = {
    title: 'Profil',
    description: 'In diesem Bereich können Sie Ihre Profildaten bearbeiten'
  };

  user; // full user with firebase and local...

  model = {
    firstName: '',
    lastName: '',
    company: '',
    companyPassword: '',
  };

  isAnonymous = false;

  companies = [];

  constructor(private userService: UserService, private db: AngularFireDatabase, private snackBar: MatSnackBar) { }

  ngOnInit() {

    this.db.list('/company').snapshotChanges().pipe(map(us => {
      return us.map((item: any) => {
        return { id: item.key, ...item.payload.val() };
      });
    })).subscribe((companies) => {
      this.companies = companies;
    });

    this.user = this.userService.user;

    if (this.userService.user.isAnonymous) {
      this.isAnonymous = true;

      this.pageTitleObj.title = 'Profil erstellen';
      this.pageTitleObj.description = 'Um weiterhin alle Optionen der App nutzen zu können, erstellen Sie bitte Ihr Konto';

      setTimeout(() => {
        this.userService.startFirebaseUi(false);
      });
    }

    if (this.user) {
      this.updateFormData();
    }

    this.userService.userObjectChanged$.subscribe((user) => {
      this.user = user;
      this.updateFormData();
    });
  }

  updateFormData() {
    if (this.user.loggedIn && this.user.local.companyId) {
      this.model.firstName = this.user.local.firstName;
      this.model.lastName = this.user.local.lastName;
      this.model.company = this.user.local.companyId;
    }
  }

  update() {

    if (!this.editUserForm.valid) {
      return;
    }

    const updateObj = {
      firstName: this.model.firstName,
      lastName: this.model.lastName,
      companyId: this.model.company,
    };

    this.db.object('/company/' + this.model.company).valueChanges().subscribe((c: any) => {

      if (c === null) {
        this.openSnackbar('Company not found!');
        return;
      }

      if (this.user.local.companyId.length === 0 && c.password !== this.model.companyPassword) {
        this.openSnackbar('Company password did not match!');
        return;
      }

      const usersRef = this.db.list('/user');
      usersRef.update(this.user.local.id, updateObj);

    });

  }

  openSnackbar(msg) {
    this.snackBar.open(msg, undefined, {
      duration: 2000,
    });
  }

  logOut() {
    this.userService.logout();
  }

}
