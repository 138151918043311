import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material';
import { Order } from '../order';
import { OrderService } from '../order.service';
import * as moment from 'moment';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {

  orderItem: Order;

  showConfirmation = false;

  canDelete = false;

  foodNames = {};

  paymentMethodsHash;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private bottomSheetRef: MatBottomSheetRef<OrderDetailsComponent>,
              private orderService: OrderService) { }

  ngOnInit() {
    this.orderItem = this.data.order;
    this.paymentMethodsHash = this.orderService.paymentMethodsHash;

    console.log('orederitem', this.orderItem);

    if (!this.orderService.allFood.length) {
      this.orderService.getAllFood().then(this.updateFoodNames);
    } else {
      this.updateFoodNames();
    }

    this.setCanCancelOrder();

  }

  updateFoodNames() {
    this.orderItem.food.forEach((item) => {
      const food = this.orderService.allFoodHash[item.foodId];
      this.foodNames[item.foodId] = food && food.name || '';
    });
  }

  tryCancel() {

    if (!this.showConfirmation) {
      this.showConfirmation = true;
      return;
    }

  }

  cancel() {
    this.showConfirmation = false;
    this.orderService.deleteOrder(this.orderItem).then(() => {
      this.bottomSheetRef.dismiss();
    });
  }

  setCanCancelOrder() {

    const paymentMethod = this.orderService.paymentMethodsHash[this.orderItem.paymentMethodId];
    if (paymentMethod && paymentMethod.internalName === 'creditCard') {
      if (this.orderItem.paymentStatus !== 'pending') {
        this.canDelete = false;
        return;
      }
    }

    const currentDayTwoOclockAfterMidnight = moment().set('hour', 2).set('minute', 0).set('second', 0).set('millisecond', 0);

    // TODO: remove this default set to false!!
    // this.canDelete = false;

    let canCancel = false;

    if (moment().isBefore(moment(this.orderItem.orderDate, 'LL'))) {
      canCancel = true;
    }

    if (moment().isSame(moment(this.orderItem.orderDate, 'LL'))) {
      canCancel = moment().isAfter(currentDayTwoOclockAfterMidnight) ? false : true;
    }

    this.canDelete = canCancel;

  }

}
