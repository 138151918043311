import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { trigger, transition, style, animate, query, stagger, animateChild } from '@angular/animations';

import * as moment from 'moment';
import 'moment/locale/de-at';
import { OrderService } from '../order/order.service';
import { Order } from '../order/order';


enum DayOrderStatus {
  Locked = 'locked',
  Opened = 'opened',
  Ordered = 'ordered'
}

@Component({
  selector: 'app-choose-city',
  templateUrl: './choose-city.component.html',
  styleUrls: ['./choose-city.component.scss'],
  animations: [
    trigger('items', [
      transition(':enter', [
        style({ transform: 'scale(0.8)', opacity: 0 }),  // initial
        animate('0.3s cubic-bezier(.8, -0.6, 0.26, 1.6)',
          style({ transform: 'scale(1)', opacity: 1 }))  // final
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate('0.2s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({
            transform: 'scale(0.8)', opacity: 0,
            height: '0px', margin: '0px'
          }))
      ])
    ]),
    trigger('list', [
      transition(':enter', [
        query('@items', stagger(80, animateChild()), { optional: true })
      ]),
    ])
  ]
})
export class ChooseCityComponent implements OnInit {

  week = [];

  currentDay;

  userOrders;

  pageTitleObj = {
    title: 'Stadt auswählen',
    description: ''
  };

  constructor(private router: Router, private userService: UserService, private orderService: OrderService) { }

  ngOnInit() {
      this.setWeeks();
  }

  setWeeks() {

    this.week = [{
      name: 'Tirol',
      key: 'tirol'
    },
    {
      name: 'Innsbruck',
      key: 'insbruck'
    }];

  }

  chooseDay(city) {
    this.orderService.currentCitySelected$.next(city);
    this.router.navigate(['/menu']);
  }

}
