import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CurrentOrder } from './current-order';
import { UserService } from '../user.service';
import { map } from 'rxjs/operators';
import { DataService } from '../shared/data.service';
import { Food } from '../food/food';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  context = 'order';

  currentOrders: any;
  currentOrdersSetup = false;
  currentDay: any;

  allFood: Array<Food> = [];
  allFoodHash = {};

  clearCurrentDayOrder$: Subject<any> = new Subject();
  togglePdfView$: Subject<boolean> = new Subject();

  paymentMethods = [];
  paymentMethodsHash = {};
  orderTypes = [];

  currentCitySelected: any;
  currentCitySelected$: Subject<any> = new Subject();


  constructor(private userService: UserService, private dataService: DataService) {
    this.currentOrders = {};

    this.userService.currentDaySelected$.subscribe((day) => {
      this.currentOrdersSetup = true;
      this.setOrderDay(day);
    });

    this.currentCitySelected$.subscribe((city) => {
      this.currentCitySelected = city;
    });

    this.dataService.getAll('paymentMethod').subscribe((paymentMethods) => {
      this.paymentMethods = paymentMethods;
      paymentMethods.forEach((pm) => {
        this.paymentMethodsHash[pm.id] = pm;
      });
    });

    this.dataService.getAll('orderType').subscribe((orderTypes) => {
      this.orderTypes = orderTypes;
    });

  }

  addFood(food) {

    const index = this.findFoodInCurrentOrder(food);

    if (index !== -1) {
      const qty = this.currentOrders[this.currentDay.date].items[index].qty + 1;
      this.currentOrders[this.currentDay.date].items[index].qty = qty;
      this.currentOrders[this.currentDay.date].items = this.currentOrders[this.currentDay.date].items.slice();
      return;
    }

    this.currentOrders[this.currentDay.date].items.push(food);
  }

  setOrderDay(day) {
    this.currentOrders[day.date] = new CurrentOrder({
      day
    });
    this.currentDay = day;
  }

  getCurrentDayOrder() {

    if (!this.currentOrdersSetup) {
      const dateObj = new Date();
      const date = moment(dateObj).format('LL');
      const day = moment(dateObj).format('dddd');
      this.userService.currentDaySelected$.next({dateObj, date, day});
    }

    if (typeof this.currentDay === 'undefined') {
      return;
    }
    return this.currentOrders[this.currentDay.date];
  }

  findFoodInCurrentOrder(food) {
    let index = -1;
    this.getCurrentDayOrder().items.forEach((item, key) => {
      if (item.id === food.id && item.name === food.name) {
        index = key;
      }
    });

    return index;
  }

  deleteOrderItem(item) {
    const index = this.findFoodInCurrentOrder(item);

    if (index === -1) {
      return;
    }

    this.getCurrentDayOrder().items.splice(index, 1);
  }

  getUserOrders() {
    return this.dataService.queryByProperty(this.context, 'userId', this.userService.user.local.id).pipe(map((orders) => {

      const newOrders = [];

      orders.forEach(element => {
        let price = 0;
        element.food.forEach((food) => {
          price += (food.price * food.quantity);
        });

        element.orderPrice = price.toFixed(2);

        newOrders.push(element);
      });

      return newOrders;
    }));
  }

  saveOrder(orderId, orderObj) {
    return this.dataService.updateObject(this.context, orderId, orderObj);
  }

  deleteOrder(orderObj) {
    return this.dataService.deleteObject(this.context, orderObj.id);
  }

  getAllFood() {

    return new Promise((resolve, reject) => {
      this.dataService.getAll('food').subscribe((food) => {
        this.allFood = food;
        this.allFood.forEach((f) => {
          if (typeof this.allFoodHash === 'undefined') {
            this.allFoodHash = {};
          }
          this.allFoodHash[f.id] = f;

          resolve();
        });
      });
    });
  }

}
