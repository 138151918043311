import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { DataService } from '../data.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit, OnDestroy {

  text: SafeHtml;
  title = '';

  constructor(private dataService: DataService, private route: ActivatedRoute, private sanitizer: DomSanitizer,
              private renderer: Renderer2) { }

  ngOnInit() {

    this.renderer.addClass(document.body, 'page-page');

    this.route.params.subscribe((params) => {
      const pageId = params.value === 'privacy-policy' ? '1' : '2' || '1';

      this.dataService.getById('page', pageId).subscribe((page: any) => {
        this.text = this.sanitizer.bypassSecurityTrustHtml(page.text);
        this.title = page.name;
      });
    });

  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'page-page');
  }

}
