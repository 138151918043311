import { Injectable } from '@angular/core';

import * as firebase from 'firebase';

import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from './user/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private authStateChangeInitialized = false;

  userObjectChanged$: Subject<any> = new Subject();
  showFirebaseUi$: Subject<boolean> = new Subject();
  showEmailNotVerifiedMsg$: Subject<boolean> = new Subject();
  dismissEmailNotVerifiedMsg$: Subject<boolean> = new Subject();
  showInactiveMsg$: Subject<boolean> = new Subject();
  currentDaySelected$: Subject<any> = new Subject();
  loading$: Subject<boolean> = new Subject();
  currentDaySelected;

  user: { loggedIn: boolean, firebase: any, local: User, isAnonymous: boolean } = {
    loggedIn: false,
    firebase: undefined,
    local: undefined,
    isAnonymous: false
  };

  isMobile = false;
  isAnonymousEnabled = false;

  constructor(private db: AngularFireDatabase, private router: Router) { }

  initAuthStateChanged() {

    if (this.authStateChangeInitialized) {
      return;
    }

    this.authStateChangeInitialized = true;

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {

        this.loading$.next(true);

        user.getIdToken().then((accessToken) => {

          if (user.isAnonymous) {
            this.userObjectChanged$.next(user);
            this.router.navigate(['/choose-day']);
            this.loading$.next(false);
            return;
          }

          const type = user.providerData[0].providerId;

          const provider = type === 'email' || type === 'password' ? 'email' : 'phone';
          const value = type === 'email' || type === 'password' ? user.email : user.phoneNumber;

          const users = this.db.list('/user', ref => ref.orderByChild(provider).equalTo(value)).snapshotChanges();

          users.pipe(map(us => {
            return us.map((item: any) => {
              return { id: item.key, ...item.payload.val() };
            });
          })).subscribe((dUser) => {
            // stalno ga kreira kad se brise iz firebase base, mozda treba napraviti provjeru da li je korisnik dosao sa sign up page????
            if (!dUser.length) {
              const itemsRef = this.db.list('user');

              const userObj = {
                id: user.uid,
                companyId: '',
                email: provider === 'email' ? user.email : '',
                firstName: '',
                isAdmin: false,
                lastName: '',
                phone: provider !== 'email' ? user.phoneNumber : '',
                externalId: ''
               };

              itemsRef.set(userObj.id, userObj);

              this.user = {
                loggedIn: true,
                firebase: user,
                local: userObj,
                isAnonymous: false
              };

              this.userObjectChanged$.next(this.user);

              // if (user.email !== null && !user.emailVerified) {
              //   user.sendEmailVerification();
              //   this.logout();
              //   this.showEmailNotVerifiedMsg$.next(true);
              // } else {
                this.router.navigate(['/user/edit']);
              // }

            } else {

              const userObj: any = dUser[0];

              this.user = {
                loggedIn: true,
                firebase: user,
                local: userObj,
                isAnonymous: false
              };

              this.userObjectChanged$.next(this.user);

              // if (user.email !== null && !user.emailVerified) {
              //   user.sendEmailVerification();
              //   this.logout();
              //   this.showEmailNotVerifiedMsg$.next(true);
              //   return;
              // }

              if (userObj['inactive'] && userObj['inactive'].toString() === 'true') {
                this.logout();
                this.showInactiveMsg$.next(true);
                return;
              }

              // if (!userObj.companyId.length) {
              //   this.router.navigate(['/user/edit']);
              //   this.loading$.next(false);
              // } else {
              //   this.router.navigate(['/choose-day']);
              //   this.loading$.next(false);
              // }
              this.router.navigate(['/choose-city']);
              this.loading$.next(false);
            }
          });

        });
      } else {
        // User is signed out.
        this.user = {
          loggedIn: false,
          firebase: undefined,
          local: undefined,
          isAnonymous: false
        };

        this.userObjectChanged$.next(this.user);
        this.loading$.next(false);
      }

      // this should prevent from redirect???!!
      return false;
    }, (error) => {
      // alert('error, see the console!!');
      console.log(error);
      this.loading$.next(false);
    });
  }

  updateUser(userResponse, db) {

    const user = userResponse.user;

    user.getIdToken().then((accessToken) => {

      if (!accessToken.length) {
        return;
      }

      const type = user.providerData[0].providerId;

      const provider = type === 'email' || type === 'password' ? 'email' : 'phone';
      const value = type === 'email' || type === 'password' ? user.email : user.phoneNumber;

      this.db.list('/user', ref => ref.orderByChild(provider).equalTo(value)).snapshotChanges().toPromise().then((dUser) => {});

    });

  }

  startFirebaseUi(hasAnonymous = true) {

    hasAnonymous = this.isAnonymousEnabled && hasAnonymous;

    (window as any).firebase = firebase;

    const firebaseui = (window as any).firebaseui;

    const self = this;
    const uiConfig = {
      autoUpgradeAnonymousUsers: hasAnonymous,
      signInFlow: 'popup',
      signInOptions: [
        {
          requireDisplayName: false,
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
        },
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID
        },
        // {
        //   defaultCountry: 'AT',
        //   provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID
        // },
        hasAnonymous && 'anonymous'
      ],
      tosUrl: () => {
        self.router.navigate(['/page/tos-policy']);
      },
      privacyPolicyUrl: () => {
        self.router.navigate(['/page/privacy-policy']);
      },
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      callbacks: {
        signInSuccessWithAuthResult: (resp) => {
          this.dismissEmailNotVerifiedMsg$.next(true);
          return false;
        },
        // uiShown: () => {
        //   this.showSpinner = false;
        // }
      }
      // Other config options...
    };

    if (firebaseui.auth.AuthUI.getInstance()) {
      const ui = firebaseui.auth.AuthUI.getInstance();
      ui.start('#firebaseui-auth-container', uiConfig);
    } else {
      const ui = new firebaseui.auth.AuthUI(firebase.auth());
      ui.start('#firebaseui-auth-container', uiConfig);
    }
  }

  logout() {
    firebase.auth().signOut();
    this.router.navigate(['/login']);
    this.showFirebaseUi$.next(true);
  }
}
