import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import {
  MatToolbarModule, MatMenuModule, MatIconModule, MatCardModule,
  MatInputModule, MatProgressSpinnerModule, MatButtonModule, MatSelectModule,
  MatSidenavModule, MatListModule, MatRippleModule, MatSnackBarModule, MatDialogModule, MatBottomSheetModule, MatBadgeModule, MatStepperModule, MatChipsModule, MatRadioModule, MatSlideToggleModule, MatCheckboxModule
} from '@angular/material';
import { LayoutModule } from '@angular/cdk/layout';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { appRoutes } from './app.routes';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireDatabase } from '@angular/fire/database';
import { EditUserComponent } from './user/edit-user/edit-user.component';
import { MenuComponent } from './menu/menu.component';
import { OrderComponent } from './order/order.component';
import { ChooseDayComponent } from './choose-day/choose-day.component';
import { PageHeaderImgComponent } from './shared/page-header-img/page-header-img.component';
import { FoodModalComponent } from './shared/food-modal/food-modal.component';
import { PageSubHeaderComponent } from './shared/page-sub-header/page-sub-header.component';
import { CartIconMenuComponent } from './order/cart-icon-menu/cart-icon-menu.component';
import { CheckoutComponent } from './order/checkout/checkout.component';
import { OrderDetailsComponent } from './order/order-details/order-details.component';
import { StripeFormComponent } from './order/payment/stripe-form/stripe-form.component';
import { HttpClientModule } from '@angular/common/http';
import { PageComponent } from './shared/page/page.component';
import { ChooseCityComponent } from './choose-city/choose-city.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EditUserComponent,
    MenuComponent,
    OrderComponent,
    ChooseDayComponent,
    PageHeaderImgComponent,
    FoodModalComponent,
    PageSubHeaderComponent,
    CartIconMenuComponent,
    CheckoutComponent,
    OrderDetailsComponent,
    StripeFormComponent,
    PageComponent,
    ChooseCityComponent,
  ],
  entryComponents: [
    FoodModalComponent,
    OrderDetailsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(
      appRoutes,
      // { enableTracing: true } // <-- debugging purposes only,
      { useHash: true }
    ),
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatSnackBarModule,
    FormsModule,
    MatRippleModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatBadgeModule,
    MatStepperModule,
    MatChipsModule,
    MatRadioModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    LayoutModule,
    PdfViewerModule
  ],
  providers: [AngularFireAuthGuard, AngularFireAuth, AngularFireDatabase],
  bootstrap: [AppComponent]
})
export class AppModule { }
