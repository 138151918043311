export class Order {
  food: Array<{foodId: string, price: string, quantity: number}>;
  // when was the order placed
  orderTime: string;
  // for which day is the order
  orderDay: string;
  // for which date is the order
  orderDate: string;
  orderPrice: string;
  orderTypeId: string;
  paymentMethodId: string;
  paymentStatus: string;
  userId: string;
  companyId: string;
  orderMonthYear: string;

  constructor(order?) {
    if (typeof order === 'undefined') {
      this.food = [];
    }

    this.food = this.setFood(order.food);
    this.orderTime = order.orderTime;
    this.orderDay = order.orderDay;
    this.orderDate = order.orderDate;
    this.orderPrice = order.orderPrice;
    this.orderTypeId = order.orderTypeId;
    this.paymentMethodId = order.paymentMethodId;
    this.paymentStatus = order.paymentStatus;
    this.userId = order.userId;
    this.companyId = order.companyId;
    this.orderMonthYear = order.orderMonthYear;
  }

  setFood(food) {
    if (typeof food === 'undefined') {
      return [];
    }

    const foods = [];

    food.forEach((f) => {
      foods.push({
        foodId: f.id,
        name: f.name,
        price: f.price,
        quantity: f.qty
      });
    });

    return foods;
  }
}
