import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';

import * as firebase from 'firebase';
import { UserService } from '../user.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  username: string;
  password: string;

  verifyEmailPending = false;
  verifyEmailPendingSnackbarRef;

  constructor(private userService: UserService, private snackBar: MatSnackBar, private renderer: Renderer2, private router: Router) { }

  ngOnInit() {

    // (window as any).firebase = firebase;
    this.userService.initAuthStateChanged();

    // firebase.auth().signInWithCustomToken(token).catch(function(error) {
    //   // Handle Errors here.
    //   var errorCode = error.code;
    //   var errorMessage = error.message;
    //   // ...
    // });

    this.userService.startFirebaseUi();

    this.userService.showFirebaseUi$.subscribe(() => {
      this.userService.startFirebaseUi();
    });

    this.userService.dismissEmailNotVerifiedMsg$.subscribe(() => {
      if(this.verifyEmailPendingSnackbarRef) {
        this.verifyEmailPendingSnackbarRef.dismiss();
      }
    });

    this.userService.showEmailNotVerifiedMsg$.subscribe((val) => {
      this.verifyEmailPending = val;

      if (this.verifyEmailPending) {
        this.verifyEmailPendingSnackbarRef = this.snackBar.open('Please check your email inbox. You need to verify your email to continue.', undefined, {
          duration: 6000,
          panelClass: ['mat-toolbar', 'mat-accent']
        });
      }
    });

    this.userService.showInactiveMsg$.subscribe((val) => {
      this.verifyEmailPending = val;

      if (this.verifyEmailPending) {
        this.verifyEmailPendingSnackbarRef = this.snackBar.open('Your account is inactive. Please contact the administrator.', undefined, {
          duration: 6000,
          panelClass: ['mat-toolbar', 'mat-accent']
        });
      }
    });

    this.renderer.addClass(document.body, 'login-page');
  }

  // startFirebaseUi() {

  //   const firebaseui = (window as any).firebaseui;

  //   const self = this;

  //   const uiConfig = {
  //     autoUpgradeAnonymousUser: true,
  //     signInFlow: 'popup',
  //     signInOptions: [
  //       'anonymous',
  //       {
  //         requireDisplayName: false,
  //         provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
  //       },
  //       {
  //         defaultCountry: 'AT',
  //         provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID
  //       },
  //     ],
  //     tosUrl:() => {
  //       self.router.navigate(['/page/tos-policy']);
  //     },
  //     privacyPolicyUrl: () => {
  //       self.router.navigate(['/page/privacy-policy']);
  //     },
  //     credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  //     callbacks: {
  //       signInSuccessWithAuthResult: (resp) => {
  //         this.verifyEmailPendingSnackbarRef.dismiss();
  //         return false;
  //       },
  //       // uiShown: () => {
  //       //   this.showSpinner = false;
  //       // }
  //     }
  //     // Other config options...
  //   };

  //   if (firebaseui.auth.AuthUI.getInstance()) {
  //     const ui = firebaseui.auth.AuthUI.getInstance();
  //     ui.start('#firebaseui-auth-container', uiConfig);
  //   } else {
  //     const ui = new firebaseui.auth.AuthUI(firebase.auth());
  //     ui.start('#firebaseui-auth-container', uiConfig);
  //   }
  // }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'login-page');
  }

}
