import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private db: AngularFireDatabase) { }

  getAll(context) {
    const dbRef = this.db.list('/' + context).snapshotChanges();
    return this.mapIds(dbRef);
  }

  getAllOrderedBy(context, orderBy) {
    const dbRef = this.db.list('/' + context, ref => ref.orderByChild(orderBy)).snapshotChanges();
    return this.mapIds(dbRef);
  }

  getById(context, id) {
    return this.db.object('/' + context + '/' + id).valueChanges();
  }

  queryByProperty(context, orderBy, value) {
    const dbRef = this.db.list('/' + context, ref => ref.orderByChild(orderBy).equalTo(value)).snapshotChanges();
    return this.mapIds(dbRef);
  }

  createObject(context, obj) {
    return this.db.list('/' + context).push(obj);
  }

  updateObject(context, id, obj) {
    return this.db.list('/' + context).update(id, obj);
  }

  addPayment(userId, orderId, obj) {
    return this.db.list('/payment/' + userId).update(orderId, obj);
  }

  deleteObject(context, id) {
    return this.db.object('/' + context + '/' + id).remove();
  }

  mapIds(dbRef: Observable<any>) {
    return dbRef.pipe(map(us => {
      return us.map((item: any) => {
        return { id: item.key, ...item.payload.val() };
      });
    }));
  }
}
