import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

import { UserService } from './user.service';
import { fromEvent } from 'rxjs';
import { throttleTime, map, pairwise, distinctUntilChanged, share, filter } from 'rxjs/operators';
import { OrderService } from './order/order.service';

enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden'
}

enum Direction {
  Up = 'Up',
  Down = 'Down',
  None = 'None'
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Road Diner';

  events: string[] = [];
  opened: boolean;
  name: string;

  isLoggedIn = false;
  isAnonymous = false;
  isMobile = false;
  alignItemsNormal = false;

  selectedDay;

  isVisibleHeaderImage = true;

  isLoginPage = false;

  isPagePage = false;

  pageTitleObj = {
    title: '',
    description: ''
  };

  visiblePdf = false;

  constructor(private router: Router, private location: Location, private route: ActivatedRoute, private orderService: OrderService,
              private userService: UserService, public breakpointObserver: BreakpointObserver, private renderer: Renderer2) {
    this.userService.initAuthStateChanged();
  }

  ngOnInit() {

    window['developledBy'] = () => {
      const msg = 'Kenan Alibegovic \nhttp://k.alibegovic.com';
      console.log(msg);
      alert(msg);
    };

    this.userService.loading$.subscribe((isLoading) => {
      if (isLoading) {
        this.renderer.removeClass(document.body, 'loaded');
      } else {
        this.renderer.addClass(document.body, 'loaded');
      }
    });

    if (this.userService.user && this.userService.user.local) {
      this.name = this.userService.user.local.firstName + ' ' + this.userService.user.local.lastName;
    }

    this.userService.userObjectChanged$.subscribe((user) => {

      if (user.isAnonymous) {
        this.name = 'Gastbenutzer';
        this.isLoggedIn = true;
        this.isAnonymous = true;
        this.userService.user.isAnonymous = true;
      }

      if (user.local) {
        this.name = user.local.firstName + ' ' + user.local.lastName;
      }
      this.isLoggedIn = user.loggedIn || user.isAnonymous;

      if (this.isLoggedIn) {
        this.renderer.addClass(document.body, 'logged-in');
      } else {
        this.renderer.removeClass(document.body, 'logged-in');
      }
    });

    this.userService.currentDaySelected$.subscribe((day) => {
      this.selectedDay = day;
    });

    this.router.events.subscribe((val) => {

      if (!(val instanceof NavigationEnd)) {
        return;
      }

      this.alignItemsNormal = this.location.path() === '/menu';
      this.isLoginPage = val.url === '/login';
      this.isPagePage = val.url === '/page/tos-policy' || val.url === '/page/privacy-policy';

      this.setTitlePage(val.url);

      const content = document.querySelector('.mat-sidenav-content');
      content.scrollTo(0, 0);

    });

    if (localStorage.getItem('isMobile') ===  '1') {
      this.isMobile = true;
      this.userService.isMobile = true;
      this.setBodyClass();
    }

    if (localStorage.getItem('platform') === 'ios') {
      this.userService.isAnonymousEnabled = true;
    }

    // TODO: make trello card to user http://localhost/?mobile=1&context=ios in webview
    this.route.queryParamMap.subscribe((val: any) => {
      const url = window.location.href.split('?');
      const platform = window.location.href.split('&');
      if (val && val.params && val.params.mobile === '1' || url && url.length && url.pop().includes('mobile=1')) {
        this.isMobile = true;
        this.userService.isMobile = true;
        localStorage.setItem('isMobile', '1');
        this.setBodyClass();
      } else {
        this.setBodyClass();
      }

      if (platform && platform.pop() === 'platform=ios') {
        localStorage.setItem('platform', 'ios');
        this.userService.isAnonymousEnabled = true;
      }
    });

    this.orderService.togglePdfView$.subscribe((value) => {
      this.visiblePdf = value || false;
      if (this.visiblePdf) {
        this.renderer.addClass(document.body, 'is-pdf');
      } else {
        this.renderer.removeClass(document.body, 'is-pdf');
      }
    });

  }

  setBodyClass() {
    this.renderer.addClass(document.body, this.isMobile ? 'mobile' : 'desktop');
  }

  onScroll($event) {
    console.log($event);
  }

  closePdf() {
    this.orderService.togglePdfView$.next(false);
  }

  navigateToMenu() {
    this.router.navigate(['/menu']);
  }

  navigateToEditUser() {
    this.router.navigate(['/user/edit']);
  }

  navigateToOrders() {
    this.router.navigate(['/orders/all']);
  }

  navigateToChooseDay() {
    this.router.navigate(['/choose-day']);
  }

  logOut() {
    this.userService.logout();
  }

  setTitlePage(route) {
    if (route === '/choose-day') {
      // this.pageTitleObj = {
      //   title: 'Bestelltag auswählen',
      //   description: 'Select for which day you want to create your order. You can place your orders for the upcoming day before the 02:00 in the morning of that day.'
      // };
      return;
    }

    if (route === '/orders') {
      // this.pageTitleObj = {
      //   title: 'Deine Bestellungen',
      //   description: 'The list of your previous and active orders'
      // };
      return;
    }

    if (route === '/user/edit') {
      // this.pageTitleObj = {
      //   title: 'Kontodetails',
      //   description: 'In this area you can edit your account data'
      // };
      return;
    }

    this.pageTitleObj = {
      title: '',
      description: ''
    };
  }

  goBack() {
    this.router.navigate([this.isLoggedIn ? '/user/edit' : '/login']);
  }

}
