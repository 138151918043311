import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { trigger, transition, style, animate, query, stagger, animateChild } from '@angular/animations';

import * as moment from 'moment';
import 'moment/locale/de-at';
import { OrderService } from '../order/order.service';
import { Order } from '../order/order';


enum DayOrderStatus {
  Locked = 'locked',
  Opened = 'opened',
  Ordered = 'ordered'
}

@Component({
  selector: 'app-choose-day',
  templateUrl: './choose-day.component.html',
  styleUrls: ['./choose-day.component.scss'],
  animations: [
    trigger('items', [
      transition(':enter', [
        style({ transform: 'scale(0.8)', opacity: 0 }),  // initial
        animate('0.3s cubic-bezier(.8, -0.6, 0.26, 1.6)',
          style({ transform: 'scale(1)', opacity: 1 }))  // final
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate('0.2s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({
            transform: 'scale(0.8)', opacity: 0,
            height: '0px', margin: '0px'
          }))
      ])
    ]),
    trigger('list', [
      transition(':enter', [
        query('@items', stagger(80, animateChild()), { optional: true })
      ]),
    ])
  ]
})
export class ChooseDayComponent implements OnInit {

  week = [];

  currentDay;

  userOrders;

  pageTitleObj = {
    title: 'Bestelltag auswählen',
    description: 'Wählen Sie aus, für welchen Tag Sie Ihre Bestellung erstellen möchten. Sie können Ihre Bestellungen für den kommenden Tag vor 02:00 Uhr morgens an diesem Tag eingeben.'
  };

  constructor(private router: Router, private userService: UserService, private orderService: OrderService) { }

  ngOnInit() {

    // const currentWeek = this.initWeekDays(new Date());

    if (this.userService.user.isAnonymous) {
      this.setWeeks();
      return;
    }

    this.orderService.getUserOrders().subscribe((orders) => {

      this.userOrders = orders;

      this.setWeeks();
    });

    this.currentDay = this.userService.currentDaySelected;

  }

  setWeeks() {
    const currentWeek = this.initWeekDays(new Date());

    // if (currentWeek.length !== 0 && currentWeek[currentWeek.length - 1].status !== DayOrderStatus.Locked) {
    //   this.week = currentWeek;
    //   return;
    // }

    const nextWeekDate = new Date();
    nextWeekDate.setDate(nextWeekDate.getDate() + 7);
    const nextWeek = this.initWeekDays(nextWeekDate, true);

    this.week = [...currentWeek, ...nextWeek];

  }

  initWeekDays(curr, isSecondWeek = false) {

    // const sufix = isSecondWeek ? '2' : '';

    // connst sufix =  moment(this.weekNumbers.week1[0]).week();

    const week = [];

    const daysMap = {
      Montag: 'monday',
      Dienstag: 'tuesday',
      Mittwoch: 'wednesday',
      Donnerstag: 'thursday',
      Freitag: 'friday'
    };

    const currentDayTwoOclockAfterMidnight = moment().set('hour', 2).set('minute', 0).set('second', 0).set('millisecond', 0);

    for (let i = 1; i <= 5; i++) {
      const first = curr.getDate() - curr.getDay() + i;
      const dateObj = new Date(curr.setDate(first));
      const date = moment(dateObj).format('LL');

      let status = DayOrderStatus.Locked;

      if (moment().isBefore(moment(dateObj))) {
        status = DayOrderStatus.Opened;
      }

      if (moment().isSame(moment(dateObj))) {
        status = moment().isAfter(currentDayTwoOclockAfterMidnight) ? DayOrderStatus.Locked : DayOrderStatus.Opened;
      }

      if (status === DayOrderStatus.Locked) {
        continue;
      }

      if (this.userOrders) {
        const existingOrder = this.userOrders.filter((item: Order) => item.orderDate === date);
        if (existingOrder && existingOrder.length) {
          status = DayOrderStatus.Ordered;
        }
      }

      const dayFormatted = moment(dateObj).format('dddd');

      const dayObject = {
        dateObj,
        date,
        day: moment(dateObj).format('dddd'),
        internalDay: daysMap[dayFormatted],
        status,
        weekNumber: moment(dateObj).week(),
        w2a: moment(dateObj.setDate(dateObj.getDate() - 1)),
        w2: moment(dateObj.setDate(dateObj.getDate() - 1)).week(),

      };

      week.push(dayObject);

    }

    return week;

  }

  chooseDay(day) {

    if (day.status === DayOrderStatus.Locked) {
      return;
    }

    // if (day.status === DayOrderStatus.Ordered) {
    //   this.router.navigate(['/orders/' + day.date]);
    //   return;
    // }

    this.userService.currentDaySelected = day;
    this.userService.currentDaySelected$.next(day);
    this.router.navigate(['/menu']);
  }

}
