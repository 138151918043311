import { redirectLoggedInTo, redirectUnauthorizedTo, AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { EditUserComponent } from './user/edit-user/edit-user.component';
import { MenuComponent } from './menu/menu.component';
import { OrderComponent } from './order/order.component';
// import { ChooseDayComponent } from './choose-day/choose-day.component';
import { CheckoutComponent } from './order/checkout/checkout.component';
import { PageComponent } from './shared/page/page.component';
import { ChooseCityComponent } from './choose-city/choose-city.component';

const redirectLoggedInToApp = () => redirectLoggedInTo(['/']);
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
export const appRoutes: Routes = [
  { path: '', component: AppComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  // { path: 'choose-day', component: ChooseDayComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'choose-city', component: ChooseCityComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'menu', component: MenuComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'orders/:orderDate', component: OrderComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'checkout', component: CheckoutComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'login', component: LoginComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToApp } },
  { path: 'user/edit', component: EditUserComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'page/:value', component: PageComponent },
  { path: '**', component: AppComponent }
];

// TODO: napravi client component u kojoj ce biti menu, orders, itd
// TODO: napravi admin component u kojoj ce biti sve vezano za dodavanje stvari u bazu
// TODO: napravi core component u kojoj ce biti user edit, login, ...

// sve povezano sa rutama preko children da ne bude zabune!!!
