import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef, MatDialog } from '@angular/material';
import { Food } from 'src/app/food/food';
import { trigger, transition, style, animate } from '@angular/animations';
import { OrderService } from 'src/app/order/order.service';
import { UserService } from 'src/app/user.service';
import { Router } from '@angular/router';


// import WebKit;

@Component({
  selector: 'app-food-modal',
  templateUrl: './food-modal.component.html',
  styleUrls: ['./food-modal.component.scss'],
  animations: [
    trigger('pop', [
      transition(':enter', [
        style({ transform: 'scale(0.6)', opacity: 0 }),  // initial
        animate('0.3s cubic-bezier(.8, -0.6, 0.26, 1.6)',
          style({ transform: 'scale(1)', opacity: 1 }))  // final
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate('0.2s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({
            transform: 'scale(0.6)', opacity: 0,
            height: '0px', margin: '0px'
          }))
      ])
    ]),
  ]
})
export class FoodModalComponent implements OnInit {

  foodItem: Food;

  err = '';

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private bottomSheetRef: MatBottomSheetRef<FoodModalComponent>,
              private orderService: OrderService, private dialog: MatDialog, private userService: UserService, private router: Router) { }

  ngOnInit() {
    this.foodItem = Object.assign({}, this.data.food);

    if (this.foodItem['dailyMenuOptions']) {
      // this.foodItem['dailyMenuOptionSelected'] = this.foodItem['dailyMenuOptionSelected'] || 'Suppe';
      if (typeof this.foodItem['dailyMenuOptionSelected'] === 'undefined') {
        this.foodItem['dailyMenuOptionSelected'] = {
          suppe: false,
          salat: false
        };
      }
    }
  }

  order() {

    if (this.userService.user.isAnonymous || !this.userService.user.local.companyId) {
      this.router.navigate(['/user/edit']);
      this.close();
      return;
    }

    if (this.foodItem['dailyMenuOptions']) {
      let name = this.foodItem.name;
      name += this.foodItem['dailyMenuOptionSelected'].suppe ? ' + Suppe' : '';
      name += this.foodItem['dailyMenuOptionSelected'].salat ? ' + Salat' : '';
      // this.foodItem.name = this.foodItem.name + ' + ' + this.foodItem['dailyMenuOptionSelected'] || 'Suppe';
      this.foodItem.name = name;
    }

    this.orderService.addFood(this.foodItem);
    this.close();
  }

  updatePrice() {
    if (this.foodItem['dailyMenuOptionSelected'].suppe && this.foodItem['dailyMenuOptionSelected'].salat) {
      this.foodItem.price = 10.9;
    } else if (this.foodItem['dailyMenuOptionSelected'].suppe || this.foodItem['dailyMenuOptionSelected'].salat) {
      this.foodItem.price = 9.9;
    } else {
      this.foodItem.price = 8.9;
    }
  }

  close() {
    this.bottomSheetRef.dismiss();
  }

  // triggerPdf() {
  //     try {
  //       (window as any).webkit.messageHandlers.callbackHandler.postMessage("https://firebasestorage.googleapis.com/v0/b/catering-37f8f.appspot.com/o/allergene.pdf?alt=media&token=01c4efd5-d367-49fd-a7da-aa2b52751651");
  //     } catch(err) {
  //       console.log('The native context does not exist yet');
  //       this.err = 'The native context does not exist yet';
  //     }
  // }

  openPdf() {
    this.bottomSheetRef.dismiss();
    this.orderService.togglePdfView$.next(true);
  }

  // iframeSolution() {
  //   let iframe = document.createElement('IFRAME');
  //   iframe.setAttribute('src', 'catering://' + 'pdfDownload'.replace(/ /gi, '%20'));
  //   document.documentElement.appendChild(iframe);
  //   iframe.parentNode.removeChild(iframe);
  //   iframe = null;
  // }

}
