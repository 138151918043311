import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-page-sub-header',
  templateUrl: './page-sub-header.component.html',
  styleUrls: ['./page-sub-header.component.scss'],
  animations: [
    trigger('pageSubHeader', [
      transition(':enter', [
        style({ transform: 'translate3d(0, -100%, 0)', visibility: 'visible' }),  // initial
        animate('0.3s ease',
          style({ transform: 'translate3d(0, 0, 0)', opacity: 1 }))  // final
      ]),
      transition(':leave', [
        style({ transform: 'translate3d(0, 0, 0)'}),
        animate('0.2s ease-out',
          style({
            transform: 'translate3d(0, -100%, 0)', opacity: 0 }))
      ])
    ])
  ]
})
export class PageSubHeaderComponent implements OnInit {

  @Input() pageTitle: {title: string, description: string};

  constructor() { }

  ngOnInit() {
  }

}
